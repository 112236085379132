import Flagsmith from 'flagsmith'

//
// Our Flagsmith architecture is documented at https://termly.slite.com/app/docs/7O3is47bcUuMGB
//

const OPTIONS = {
  // The flagsmith staging environment is for testing flagsmith deployments.
  // The production endpoint has an environmentID for each of production and
  // staging.
  api: 'https://flagsmith-api.production.termlyapi.io/api/v1/',
  enableLogs: false,
  environmentID: void 0,
}


export default function initFlagsmith(options) {
  const cacheFlags = supportsLocalStorage()

  return Flagsmith.init({
    ...OPTIONS,
    cacheFlags,
    ...options,
  })
}

function supportsLocalStorage() {
  try {
    // If the user has cookies disabled, we won't have access to localStorage,
    // and an exception will be thrown. So really, this will only ever return
    // true...but the linter will complain unless I do something "interesting"
    // with `window.localStorage`, so I figure this'll do.
    //
    // The reason we're doing this is just to keep these errors out of Sentry,
    // thus hoping to save ourselves a few bucks.
    return Boolean(window.localStorage)
  } catch (error) {
    return false
  }
}

import { createSelector } from 'reselect'

import { getFormValues } from 'redux-form'

import {
  FONT_FAMILIES,
  FONT_SIZES,
} from 'constants/fontSetting'

import {
  CREATE_NEW_THEME_ID,
  DEFAULT_THEME_ID,
} from 'constants/documentThemes'

import formId from 'constants/formIds/themeEditor'

import Select from 'components/Select'
import Dropdown from 'components/Dropdown'
import ColorPicker from 'components/Input/ColorPicker'
import CustomColorInput from 'components/elements/CustomColorInput'
import { getDocument } from 'selectors/responses/website'
import { selectFormValue as selectThemeSelectorValue } from 'selectors/forms/themeSelector'
import { updateText } from 'reducers/panels/policy'
import { store } from 'store'

export const selectFormValue = getFormValues(formId.name)

export const getOnTextChange = () => (text) => store.dispatch(updateText(text))

export const getTheme = createSelector(
  getDocument,
  selectThemeSelectorValue,
  (document, themeSelectorValue) => {
    if ( !document ) {
      return null
    }

    return themeSelectorValue
      ? themeSelectorValue.themeId === CREATE_NEW_THEME_ID ||
        themeSelectorValue.themeId === DEFAULT_THEME_ID
        ? document.themes.default_theme?.data
        : document.themes.customize_themes.find(
          (theme) =>
            parseInt(theme.id, 10) ===
              parseInt(themeSelectorValue.themeId, 10)
        )?.data
      : null
  }
)

export const getPreviewTheme = createSelector(
  selectFormValue,
  (formValue) => {
    if (!formValue) return null

    return Object.keys(formValue).reduce((pv, cv) => {
      pv.push({ name: cv, style: formValue[cv].style })
      return pv
    }, [])
  }
)

export const getFontFamilyFieldGenerator = createSelector(() => (name) => ({
  name: formId.fields[name].style.fontFamily,
  component: Dropdown,
  containerClass: 'funnel-v2-dropdown',
  arrow: 'blue-arrow',
  options: FONT_FAMILIES,
}))

export const getFontSizeFieldGenerator = createSelector(() => (name) => ({
  name: formId.fields[name].style.fontSize,
  component: Select,
  className: 'funnel-v2-select-classname',
  containerClass: 'funnel-v2-select-container-class',
  arrow: 'blue-arrow',
  options: FONT_SIZES,
}))

export const getFontColorFieldGenerator = createSelector(() => (name) => ({
  name: formId.fields[name].style.color,
  component: CustomColorInput,
}))

export const getTextThemes = createSelector(
  getTheme,
  getFontFamilyFieldGenerator,
  getFontSizeFieldGenerator,
  getFontColorFieldGenerator,
  (
    theme,
    fontFamilyFieldGenerator,
    fontSizeFieldGenerator,
    fontColorFieldGenerator
  ) =>
    theme
      ? theme.elements_css
        .filter((css) => css.name !== 'body')
        .map((css) => ({
          name: css.name,
          fields: {
            fontFamilyField: fontFamilyFieldGenerator(css.name),
            fontSizeField: fontSizeFieldGenerator(css.name),
            fontColorField: fontColorFieldGenerator(css.name),
          },
        }))
      : null
)

export const getBackgroundThemeField = createSelector(() => ({
  name: formId.fields.body.style.background,
  component: ColorPicker,
}))

export const getInitialValues = createSelector(
  getTheme,
  (theme) =>
    theme &&
    theme.elements_css.reduce(
      (pv, cv) => ({ ...pv, [cv.name]: { style: cv.style } }),
      {}
    )
)

export const getShow = createSelector(
  selectThemeSelectorValue,
  (themeSelectorValue) =>
    themeSelectorValue && themeSelectorValue.themeId !== DEFAULT_THEME_ID
)
